import React, { CSSProperties, PureComponent } from 'react'
import { ScrollView } from '@cantonjs/react-scroll-view'
import { GridSize } from '@material-ui/core/Grid'
import TablePagination from '@material-ui/core/TablePagination'
import { get } from 'lodash'

import {
  ensureArrayFormat,
  normaliseStringForComparison,
  sortArrayOfObjects,
  toLowerCaseCustom,
  toValidPfDate,
} from '../../../utils'

import DataTableBody from './DataTableBody'
import DataTableHeader, { IColumnConfig } from './DataTableHeader'
import { ColorPalette } from '../../../config'
import LoadingModal from '../../Modals/LoadingModal'
import { UserFeedback } from '../../GeneralUI/Feedback/UserFeedback'
import { TableFilterService } from '../../../services/tableFilterService'

export type CustomRowStyle = {
  rowId: any
  rowStyle: CSSProperties | { ':hover': CSSProperties }
}

export type DataTableFilterState = Record<string, Record<string, boolean>>

export type CustomFilterConfig = {
  key: string
  label: string
}

export type CustomFilterState = {
  selectedField: string
  filterType: string
  filterValue: string
  key?: string
}

interface DataTableProps {
  tableData: Record<string, any>[]
  columnConfig: IColumnConfig[]
  customComponentConfig?: any
  tableWidth: GridSize
  disableRowClick?: boolean
  style?: React.CSSProperties | undefined
  selectedRowItemId: string
  customFilterConfig?: CustomFilterConfig[]
  customFilterState?: CustomFilterState[]
  filterModeEnabled?: boolean
  filterState?: DataTableFilterState
  selectionEnabled?: boolean
  sortEnabled?: boolean
  trimText?: boolean
  rowClickAsSelect?: boolean
  initialSelectedRows?: string[]
  initialSelectedAllEnabled?: boolean
  disabled?: boolean
  rowHeight?: number
  userFeedbackMessage?: string
  currentCellInputFocus?: string[]
  applyCustomRowStyle?: (rowId: string) => CSSProperties | { ':hover': CSSProperties }
  onFilterChange?: (allSortedData: any[]) => void
  onRowClick?: (selectedRow: Record<string, any>) => void
  onRowSelect?: (selectedRows: any) => void
  onSelectAll?: () => void
  onMounted?: () => void
  onCellInputChange?: (rowId: string, itemId: string, value: string) => void
  onCellInputFocus?: (rowId: string, itemId: string) => void
}

interface DataTableState {
  selectedRows: any
  allSortedData: any[]
  allSortedDataOnPage: any[]
  sortDirection: 'asc' | 'desc' | 'ascending' | 'descending'
  columnIndexToSort: string
  rowsPerPage: number
  currentPage: number
  searchString: string
  allFilterState: DataTableFilterState
  filterOptions: string[]
  selectedFilterOptions: string[]
  headerRefreshTimestamp: number
  customFilterOpen: boolean
  screenHeight: number
  customFilterState: CustomFilterState[]
  loadingModalOpen: boolean
  loadingModalMessage: string
  mounted: boolean
  customFilterApplied: boolean
}

const tableFilterService = new TableFilterService()

class DataTable extends PureComponent<DataTableProps, DataTableState> {
  state: DataTableState = {
    headerRefreshTimestamp: Date.now(),
    selectedRows: {},
    allSortedData: [],
    allSortedDataOnPage: [],
    sortDirection: 'asc',
    columnIndexToSort: '',
    rowsPerPage: 50,
    currentPage: 0,
    searchString: '',
    allFilterState: {},
    filterOptions: [],
    selectedFilterOptions: [],
    customFilterOpen: false,
    customFilterState: this.props.customFilterState || [],
    screenHeight: window.innerHeight,
    loadingModalOpen: false,
    loadingModalMessage: '',
    mounted: false,
    customFilterApplied: false,
  }

  componentDidMount() {
    if (this.props.initialSelectedAllEnabled) {
      this.selectedAll()
    }

    if (this.props.initialSelectedRows && this.props.initialSelectedRows.length > 0) {
      let selectedRows: Record<string, boolean> = {}
      this.props.initialSelectedRows.forEach((rowId: string) => (selectedRows[rowId] = true))
      this.setState({ selectedRows })
    }

    let { tableData, columnConfig, filterState } = this.props
    try {
      const hasActiveFilters = Boolean(filterState && Object.keys(filterState).length)
      if (hasActiveFilters) {
        this.updateFilterState(filterState as DataTableFilterState)
        this.refreshTableHeader()
      } else {
        let allFilterState = tableFilterService.generateFilterState(columnConfig, tableData, {})
        tableData = tableFilterService.applyFilters(tableData, allFilterState)
        this.setState({ allSortedData: tableData, allFilterState }, () => {
          this.loadPage(null, this.state.currentPage, true)
        })
      }
    } catch (error) {
      console.error('error:', error)
    }

    this.setState({ mounted: true })
    if (this.props.onMounted) {
      this.props.onMounted()
    }
  }

  componentDidUpdate(prevProps: DataTableProps) {
    let { customFilterState } = this.props
    const { mounted, customFilterApplied, allSortedDataOnPage } = this.state
    if (mounted && !customFilterApplied && allSortedDataOnPage.length > 0 && customFilterState?.length) {
      customFilterState = tableFilterService.cleanFilterState(customFilterState)
      this.applyCustomFilters(customFilterState)
      this.setState({ customFilterApplied: true })
    }
  }

  refreshTableHeader = () => this.setState({ headerRefreshTimestamp: Date.now() })

  setLoadingModalMessage = (message: string) => {
    this.setState({ loadingModalOpen: true, loadingModalMessage: message })
  }

  resetLoadingModalMessage = () => {
    this.setState({ loadingModalOpen: false, loadingModalMessage: '' })
  }

  reload(
    toGenerateFilterState?: boolean,
    generateFilterCallback?: (allFilterState: DataTableFilterState) => void,
  ): void {
    this.setLoadingModalMessage('Refreshing table data')
    let { tableData, columnConfig, filterState } = this.props
    if (!toGenerateFilterState && filterState && Object.keys(filterState).length) {
      this.updateFilterState(filterState)
      this.refreshTableHeader()
      this.resetLoadingModalMessage()
    } else {
      const allFilterState = tableFilterService.generateFilterState(columnConfig, tableData, this.state.allFilterState)
      let data =
        this.props.customFilterState?.length && this.state.customFilterApplied ? this.state.allSortedData : tableData
      data = tableFilterService.applyFilters(data, allFilterState)
      this.setState({ allSortedData: data, allFilterState }, () => {
        this.search(this.state.searchString, data)
        if (generateFilterCallback) {
          generateFilterCallback(allFilterState)
        }
        this.resetLoadingModalMessage()
      })
    }
  }

  getFilterState = () => {
    return this.state.allFilterState
  }

  getSelectedRows = () => {
    return this.state.selectedRows
  }

  updateFilterState = (updatedFilterState: DataTableFilterState, callback?: () => void) => {
    if (tableFilterService.shouldCalculateFilterState(updatedFilterState)) {
      updatedFilterState = tableFilterService.generateFilterState(
        this.props.columnConfig,
        this.props.tableData,
        updatedFilterState,
      )
    }
    const tableData = tableFilterService.applyFilters(this.props.tableData, updatedFilterState)
    this.setState({ allSortedData: tableData, allFilterState: updatedFilterState }, () => {
      this.search(this.state.searchString, tableData)
      if (callback) {
        callback()
      }
    })
  }

  async search(searchString: string, allSortedData?: any[]) {
    allSortedData = allSortedData ?? this.state.allSortedData
    if (this.state.searchString !== searchString) {
      allSortedData = this.props.tableData
      if (this.state.columnIndexToSort) {
        await this.sort(this.state.columnIndexToSort)
      }
    }
    allSortedData = allSortedData.filter((dataItem) => {
      let shouldReturn = false
      Object.keys(dataItem).forEach((keyName) => {
        const obj = dataItem[keyName]
        if (typeof obj !== 'string') {
          return
        }
        const field = toLowerCaseCustom(obj)
        if (field.includes(toLowerCaseCustom(searchString))) {
          shouldReturn = true
        }
      })
      return shouldReturn
    })
    this.setState({ searchString, allSortedData }, () => this.loadPage(null, 0, true))
    this.onFilterChange(allSortedData)
  }

  async sort(columnIndexToSort: string): Promise<void> {
    return new Promise<void>((resolve) => {
      const sortDirection =
        this.state.columnIndexToSort === columnIndexToSort && this.state.sortDirection === 'ascending'
          ? 'descending'
          : 'ascending'

      const allSortedData = sortArrayOfObjects(
        this.props.columnConfig[parseInt(columnIndexToSort)].id,
        sortDirection,
        this.state.allSortedData,
      )
      const pageResult = this.createPageBatch(allSortedData, this.state.currentPage)

      this.setState(
        {
          columnIndexToSort,
          sortDirection,
          allSortedData,
          allSortedDataOnPage: pageResult.pageData,
        },
        () => resolve(),
      )
    })
  }

  loadPage(event: React.MouseEvent<HTMLButtonElement> | null, page: number, override: boolean = false): void {
    page += 1
    if (page !== this.state.currentPage || override) {
      const pageResult = this.createPageBatch(this.state.allSortedData, page)
      this.setState({
        allSortedDataOnPage: pageResult.pageData,
        currentPage: page,
      })
    }
  }

  removeFilters = (rowItemId: string) => {
    let { allFilterState } = this.state
    Object.keys(allFilterState).forEach((headerKey) => {
      if (headerKey === rowItemId) {
        Object.keys(allFilterState[headerKey]).forEach((filterKey) => (allFilterState[headerKey][filterKey] = false))
      }
    })
    this.updateFilterState(allFilterState)
    this.refreshTableHeader()
  }

  selectedAll() {
    let selectedRows: any = {}
    let allSelected = Object.keys(this.state.selectedRows).length === this.state.allSortedData.length
    if (!allSelected) {
      this.state.allSortedData.forEach((itemData) => (selectedRows[itemData.id] = true))
    }
    if (this.props.onRowSelect) {
      this.props.onRowSelect(selectedRows)
    }
    this.setState({ selectedRows })
  }

  rowSelectionHandler(rowData: Record<string, any>) {
    let selectedRows = { ...this.state.selectedRows }
    const rowId = rowData.id
    if (selectedRows[rowId]) {
      delete selectedRows[rowId]
    } else {
      selectedRows[rowId] = true
    }
    if (this.props.onRowSelect) {
      this.props.onRowSelect(selectedRows)
    }
    this.setState({ selectedRows })
  }

  createPageBatch(data: any[], pageNum: number): { pageData: any[]; numberOfPages: number } {
    const numberOfPages = Math.ceil(data.length / this.state.rowsPerPage)
    const pageData = data.slice((pageNum - 1) * this.state.rowsPerPage, pageNum * this.state.rowsPerPage)
    return { pageData, numberOfPages }
  }

  toggleCustomFilter = (saveAndClose: boolean) => {
    let { customFilterState } = this.state
    if (saveAndClose) {
      customFilterState = tableFilterService.cleanFilterState(customFilterState)
      this.applyCustomFilters(customFilterState)
    }
    this.setState({ customFilterOpen: !saveAndClose, customFilterState })
  }

  applyCustomFilters = (
    customFilterState: { selectedField: string; filterType: string; filterValue: string; key?: string }[],
  ) => {
    function normaliseWithDateParsing(value: any) {
      let normalisedFilterValue = normaliseStringForComparison(value)
      return toValidPfDate(normalisedFilterValue, 'YYYYMMDD') // Original value will simply pass through if it's not a date
    }

    let data = this.props.tableData
    customFilterState.forEach((filterStateItem) => {
      data = [...data].filter((rowItem) => {
        const { filterType, filterValue, key } = filterStateItem
        const normalisedFilterValue = normaliseWithDateParsing(filterValue)
        const filterValueIsNumber = !isNaN(parseInt(normalisedFilterValue))

        const value = get(rowItem, key as string, [])
        let valueArray = ensureArrayFormat(value)
        let shouldReturn = false
        for (const arrayValue of valueArray) {
          const normalisedArrayValue = normaliseWithDateParsing(arrayValue)
          const arrayValueIsNumber = !isNaN(parseInt(normalisedArrayValue))

          if (
            (filterType === '=' && normalisedArrayValue.includes(normalisedFilterValue)) ||
            (filterType === '>=' &&
              arrayValueIsNumber &&
              filterValueIsNumber &&
              parseInt(normalisedArrayValue) >= parseInt(normalisedFilterValue)) ||
            (filterType === '<=' &&
              arrayValueIsNumber &&
              filterValueIsNumber &&
              parseInt(normalisedArrayValue) <= parseInt(normalisedFilterValue))
          ) {
            shouldReturn = true
            break
          }
        }
        return shouldReturn
      })
    })
    this.setState({ allSortedData: data }, () => {
      this.search(this.state.searchString, data)
    })
  }

  onFilterChange = (allSortedData: any[]) => {
    if (this.props.onFilterChange !== undefined) {
      this.props.onFilterChange(allSortedData)
    }
  }

  handleRowClick = (selectedRow: Record<string, any>) => {
    const { disableRowClick, rowClickAsSelect, onRowClick = () => {} } = this.props
    if (disableRowClick) {
      return
    }
    if (rowClickAsSelect) {
      this.rowSelectionHandler(selectedRow)
      return
    }
    onRowClick(selectedRow)
  }

  handleFilterData = (updatedFilterState: DataTableFilterState) => {
    this.setLoadingModalMessage('Applying filter...')
    setTimeout(() => {
      this.updateFilterState(updatedFilterState, () => this.resetLoadingModalMessage())
    }, 100)
  }

  handleRemoveFilters = (rowItemId: string) => {
    this.setLoadingModalMessage('Removing filter...')
    setTimeout(() => {
      this.removeFilters(rowItemId)
      this.resetLoadingModalMessage()
    }, 100)
  }

  render() {
    const columnConfig = this.props.columnConfig.map((configItem) => {
      return { ...configItem, sizeFactor: configItem?.sizeFactor || 1 }
    })
    const combinedSizeFactor = columnConfig.reduce<number>((arr, el) => {
      return arr + el.sizeFactor
    }, 0)

    let tableBodyAndPageNav = null
    if (!this.state.loadingModalOpen && this.state.allSortedDataOnPage.length === 0) {
      tableBodyAndPageNav = <UserFeedback message={this.props.userFeedbackMessage || 'No data to display'} />
    }
    if (this.state.allSortedDataOnPage.length > 0) {
      const {
        customComponentConfig,
        selectionEnabled,
        customFilterConfig,
        disableRowClick,
        trimText,
        disabled,
        rowHeight,
        tableWidth,
        currentCellInputFocus,
        applyCustomRowStyle,
        onCellInputChange,
        onCellInputFocus,
        onRowSelect,
        ...otherProps
      } = this.props

      tableBodyAndPageNav = (
        <>
          <ScrollView style={{ backgroundColor: ColorPalette.CARD_WHITE, display: 'flex', flex: 1 }}>
            <DataTableBody
              data={this.state.allSortedDataOnPage}
              tableWidth={tableWidth}
              combinedSizeFactor={combinedSizeFactor}
              customComponentConfig={customComponentConfig}
              selectionEnabled={selectionEnabled}
              selectedRows={this.state.selectedRows}
              onRowSelect={(selectedRow: Record<string, any>) => this.rowSelectionHandler(selectedRow)}
              onClick={this.handleRowClick}
              customFilterConfig={customFilterConfig}
              disableRowClick={disableRowClick}
              trimText={trimText}
              applyCustomRowStyle={applyCustomRowStyle}
              disabled={disabled}
              rowHeight={rowHeight}
              currentCellInputFocus={currentCellInputFocus}
              onCellInputChange={onCellInputChange}
              onCellInputFocus={onCellInputFocus}
              {...otherProps} // Need this for the custom components which require access to dynamically specified functions
            />
          </ScrollView>
          <TablePagination
            component="div"
            count={this.state.allSortedData.length}
            rowsPerPage={this.state.rowsPerPage}
            rowsPerPageOptions={[]}
            page={Math.max(this.state.currentPage - 1, 0)}
            onChangePage={(event, page) => this.loadPage(event, page)}
            style={{
              backgroundColor: ColorPalette.CARD_WHITE,
              flex: '0 0 auto',
            }}
          />
        </>
      )
    }

    const allSelected = Object.keys(this.state.selectedRows).length === this.state.allSortedData.length
    return (
      <div
        style={{
          ...styles.tableContainer,
          ...this.props.style,
        }}>
        <DataTableHeader
          columnConfig={columnConfig}
          combinedSizeFactor={combinedSizeFactor}
          customComponentConfig={this.props.customComponentConfig}
          columnIndexToSort={this.state.columnIndexToSort}
          sortDirection={this.state.sortDirection}
          selectionEnabled={this.props.selectionEnabled}
          allSelected={allSelected}
          tableWidth={this.props.tableWidth}
          filterModeEnabled={this.props.filterModeEnabled}
          filterData={this.handleFilterData}
          allFilterState={this.state.allFilterState}
          onSelectAll={() => this.selectedAll()}
          removeFilters={this.handleRemoveFilters}
          onSortRequest={(sortcol: string) => this.sort(sortcol)}
          customFilterState={this.state.customFilterState}
          customFilterOpen={this.state.customFilterOpen}
          customFilterConfig={this.props.customFilterConfig}
          updateCustomFilterState={(customFilterState) => this.setState({ customFilterState })}
          toggleCustomFilter={(saveAndClose: boolean) => this.toggleCustomFilter(saveAndClose)}
          sortEnabled={this.props.sortEnabled}
          key={`${this.state.headerRefreshTimestamp}_${
            this.props.customFilterConfig ? Object.keys(this.props.customFilterConfig).join('-') : ''
          }`}
        />
        <div style={{ display: 'flex', flex: 1, flexDirection: 'column', justifyContent: 'space-between' }}>
          {tableBodyAndPageNav}
        </div>
        <LoadingModal open={this.state.loadingModalOpen}>{this.state.loadingModalMessage}</LoadingModal>
      </div>
    )
  }
}

const styles = {
  tableContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column' as 'column',
  },
}

export default DataTable
